import React from "react";
import { Container, Row, Col, Image, Card, Button, Navbar, Alert, CardText } from "react-bootstrap";
import styled from 'styled-components';
import Seo from "../components/Seo";
import Layout from "../components/Layouts/MainLayout";
import SubForm from "../components/Forms/SubForm";

import frontDesign from "../images/ONA_Design_Front.jpg";
import backDesign from "../images/ONA_Design_Back.jpg";
import VngleMingle from "../images/ona/vngle_mingle.png";
import Rooftop from "../images/ona/atl_rooftop.png"
import Speakeasy from "../images/ona/speakeasy.png";
import Biscuit from "../images/ona/biscuit.png";
import Special from "../images/ona/special_offer.png";
import Tuned from "../images/ona/stay_tuned.png";

// to hide footer, add this to Layout: hideFooter={true}

const ONA = () => {
  const events = [
    { title: "vngle mingle", link: "https://www.eventbrite.com/e/1015635772937?aff=oddtdtcreator" },
    { title: "rooftop", link: "https://www.eventbrite.com/e/1015875680507?aff=oddtdtcreator" },
    { title: "speakeasy", link: "https://www.eventbrite.com/e/1015877726627?aff=oddtdtcreator" }
  ];

  const eventDetails = [
    {
      image: VngleMingle,
      date: "Wednesday, September 18th · 7:00pm - 8:30pm",
      description: "🍸 Vngle Mingle at the Hard Rock Cafe",
      buttonColor: "#FFC107"
    },
    {
      image: Rooftop,
      date: "Thursday, September 19th · 6:30pm - 8:30pm",
      description: "🌃ִ Rooftop Kickback in South Downtown ATL",
      buttonColor: "#3399FF",
      border: "none"
    },
    {
      image: Speakeasy,
      date: "Friday, September 20th · 6:30 - 7:30pm",
      description: "🥃 Downtown ATL's Hidden Speakeasy + Cigar Lounge",
      buttonColor: "#FF66CC",
      border: "none"
    },
    {
      image: Biscuit,
      date: "Friday, September 20th · 11:00am",
      description: "🐕 Doggie Destress - Come meet the famous Biscuit JD, Vngle's Pawyer ⚖️ @ the Vngle Booth",
      bonus: "#BonusEvent"
    }
  ];

  return (
    <Layout>
      <Seo title="Don’t Miss Our ONA 2024 Event and Exclusive ONA Deal" />
      <Container>
        <Row>
          <ResponsiveTitle className="display-4 fw-bolder text-center">  
            <span className="highlight">
            Happy 25th Anniversary <span style={{ color: '#E80069' }}>ONA</span> From <span style={{ color: '#07B6E6', fontWeight: 'bold' }}>Vngle</span>!
            </span>
          </ResponsiveTitle>
          <ResponsiveSubtitle className="font-sans-serif h4 text-center"> 
            <strong>Make sure you grab this year's ONA shirt</strong>
          </ResponsiveSubtitle>
        </Row>
        <Row className="mb-4">
          <Col xs={6}>
            <Card>
              <Card.Img variant="top" src={frontDesign} alt="Anniversary T-Shirt Design Front" />
            </Card>
          </Col>
          <Col xs={6}>
            <Card>
              <Card.Img variant="top" src={backDesign} alt="Anniversary T-Shirt Design Back" />
            </Card>
          </Col>
        </Row>
        <Row>
          <ResponsiveTitle>RSVP For Our <span style={{ color: '#E80069' }}>ONA</span> Events</ResponsiveTitle>
        </Row>
        {/**text-center text-lg-start */}
        <Row className="mb-4">
          {eventDetails.map((event, index) => (
            <Col xl={3} className="mb-4" key={index}>
              <StyledCard>
                <ImageContainer>
                  <StyledCardImage variant="top" src={event.image} />
                </ImageContainer>
                <StyledCardBody>
                  <Card.Title className="font-sans-serif h5 fw-bold fst-italic">{event.date}</Card.Title>
                  <Card.Text className="font-sans-serif h5">{event.description}</Card.Text>
                  {index < 3 && (
                    <StyledButton 
                      href={events[index].link} 
                      className="fw-bolder"
                      style={{ backgroundColor: event.buttonColor, border: event.border }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RSVP for {events[index].title}
                    </StyledButton>
                  )}
                  {index === 3 && (
                    <Card.Text className="fw-bold">{event.bonus}</Card.Text>
                  )}
                </StyledCardBody>
              </StyledCard>
            </Col>
          ))}
        </Row>
        <div className="mb-4 py-5" style={{ backgroundColor: "#E80069", width: "100vw", position: "relative", left: "calc(-50vw + 50%)", overflowX: "none" }}>
          <Container fluid className="px-0">
            <Container>
              <Row className="align-items-center">
                <Col xl={6} className="d-flex align-items-center justify-content-center mb-5 mb-xl-0">
                  <Image src={Special} alt="Special Offer" fluid style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
                <Col xl={6}>
                  <Card className="h-100 shadow" style={{border: "none"}}>
                    <Card.Header className="text-white text-center py-3" style={{ backgroundColor: "#1E1C21" }}>
                      <ResponsiveTitle className="mb-0 display-6 fw-bolder">ONA 3-Month Deal Bundle</ResponsiveTitle>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text className="font-sans-serif fst-italic h5"> 
                        ONA community members are entitled to the following when they take advantage of our <strong>3 Month Deal Bundle</strong>:
                      </Card.Text>
                      <Card.Text className="font-sans-serif">
                        • <strong>$250 off</strong> content membership each month
                      </Card.Text>
                      <Card.Text className="font-sans-serif">
                        • <strong>5-15</strong> on-demand story requests each month
                      </Card.Text>
                      <Card.Text className="font-sans-serif">
                        • <strong>Access to Vngle's Grassroots Newswire:</strong> a growing inventory of verified photo and video content
                      </Card.Text>
                      <Button href="https://docs.google.com/forms/d/1fydgV8_MqE48TW5jeD7LGjguznHwbnCMhpuqs0fBGog/edit?ts=66e35418" target="_blank" rel="noopener noreferrer" className="mt-3 d-flex justify-content-center" style={{ textTransform: 'uppercase', fontWeight: 700 }}>
                        Request your membership here by 09/29/24
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Container>
      </div>
        <Row className="mb-4">
          <Col>
            <ResponsiveTitle className="mb-3">Welcome Message from Our Founder</ResponsiveTitle>
            <div className="ratio ratio-16x9">
              <iframe 
                width="1280" 
                height="720" 
                src="https://www.youtube.com/embed/GXBbukSVu34" 
                title="Happy 25th Anniversary ONA! Welcome to ATL." 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen>
              </iframe>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={6} className="mb-4 mb-xl-0">
            <Card className="h-100 shadow">
              <Card.Header className="bg-dark text-white text-center py-3">
                <ResponsiveTitle className="mb-0">Projects Coming Soon</ResponsiveTitle>
              </Card.Header>
              <Card.Body>
                <Card.Text className="font-sans-serif">
                  • <strong>Vngle x GA State collaborative initiative:</strong> Expanding media capabilities to proactively address healthcare challenges in refugee communities across GA state.
                </Card.Text>
                <Card.Text className="font-sans-serif">
                  • <strong>Vngle x Harvard Medical Publishing collaborative study:</strong> The immigrant perspective - unveiling healthcare challenges in one of America's most diverse communities.
                </Card.Text>
                <Card.Text className="font-sans-serif">
                  • <strong>Starling Lab (USC & Stanford) case study:</strong> Real-time application of decentralized technology and digital innovation in expanding reach and preserving data integrity and source of truth in traditional journalism and editorial workflows.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} className="d-flex justify-content-center">
            <Image src={Tuned} alt="Stay Tuned" fluid />
          </Col>
        </Row>
      </Container>
      <SubForm />
    </Layout>
  );
};

export default ONA;

const ResponsiveTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }
`;
const ResponsiveSubtitle = styled.p`
  font-size: 1rem;
  text-align: center;
  font-family: sans-serif;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;
const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ImageContainer = styled.div`
  height: auto;
  overflow: hidden;
`;
const StyledCardImage = styled(Card.Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;
const StyledCardBody = styled(Card.Body)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 700;
  margin-top: auto;
`;